<template>
  <div>
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 0.000244141C9.55229 0.000244141 10 0.447959 10 1.00024V10.586L12.2929 8.29314C12.6834 7.90261 13.3166 7.90261 13.7071 8.29314C14.0976 8.68366 14.0976 9.31683 13.7071 9.70735L9.70711 13.7074C9.31658 14.0979 8.68342 14.0979 8.29289 13.7074L4.29289 9.70735C3.90237 9.31683 3.90237 8.68366 4.29289 8.29314C4.68342 7.90261 5.31658 7.90261 5.70711 8.29314L8 10.586V1.00024C8 0.447959 8.44772 0.000244141 9 0.000244141ZM1 12.0002C1.55228 12.0002 2 12.448 2 13.0002V14.0002C2 14.5307 2.21071 15.0394 2.58579 15.4145C2.96086 15.7895 3.46957 16.0002 4 16.0002H14C14.5304 16.0002 15.0391 15.7895 15.4142 15.4145C15.7893 15.0394 16 14.5307 16 14.0002V13.0002C16 12.448 16.4477 12.0002 17 12.0002C17.5523 12.0002 18 12.448 18 13.0002V14.0002C18 15.0611 17.5786 16.0785 16.8284 16.8287C16.0783 17.5788 15.0609 18.0002 14 18.0002H4C2.93913 18.0002 1.92172 17.5788 1.17157 16.8287C0.421427 16.0785 0 15.0611 0 14.0002V13.0002C0 12.448 0.447715 12.0002 1 12.0002Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class DownloadNew extends Vue {}
</script>
